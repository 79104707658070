.hw__projects-tabs {
  display: flex;
  justify-content: center;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 20px;
  margin: 0 auto 40px;
  width: fit-content;
}

.hw__projects-tab-selected,
.hw__projects-tab-selected:hover {
  color: var(--color-theme);
  border-color: var(--color-theme);
  cursor: default;
}

.hw__projects-tab-sup {
  position: relative;
  top: -4px;
  margin-left: 4px;
  font-size: 0.875rem;
}

.hw__projects-tab-desc {
  color: var(--color-desc);
  margin: 0 auto 30px;
  padding: 0 20px;
  text-align: center;
  font-style: italic;
  max-width: 800px;
}

.hw__projects-items {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  padding: 20px;
}

.hw__projects-item {
  width: 100%;
}

.hw__projects-item-card {
  position: relative;
  display: flex;
  flex-direction: column;
  border-radius: 12px;
  overflow: hidden;
  margin-bottom: 10px;
  box-shadow: var(--box-shadow);
}

.hw__projects-item-img {
  aspect-ratio: 3 / 2;
  overflow: hidden;
}

.hw__projects-item-img img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  transition: 0.3s;
}

.hw__projects-content {
  display: flex;
  flex-direction: column;
  position: absolute;
  height: calc(100% + 16px);
  width: 100%;
  top: calc(100% - 40px);
  background: var(--color-projects-card-bg);
  backdrop-filter: blur(4px);
  transition: 0.4s cubic-bezier(0.18, 0.89, 0.31, 1.21);
  box-shadow: var(--box-shadow);
}

.hw__projects-item-card:hover .hw__projects-content {
  top: 0;
}

.hw__projects-title {
  height: 40px;
  line-height: 40px;
  text-align: center;
  font-size: 1.25rem;
  font-weight: bold;
  padding: 0 20px;
  transition: 0.4s;
}

.hw__projects-item-card:hover .hw__projects-title {
  height: 56px;
  line-height: 56px;
}

.hw__projects-desc {
  max-height: 116px;
  overflow-y: auto;
  padding: 0 20px;
  font-size: 15px;
  text-align: justify;
}

/* Change the background of the scrollbar track */
.hw__projects-desc::-webkit-scrollbar-track {
  background-color: var(--color-font-reverse);
  border-radius: 4px;
}

/* Set the style of the scrollbar */
.hw__projects-desc::-webkit-scrollbar {
  width: 8px;
  /* Width of the scrollbar */
}

/* Set the style of the scrollbar slider */
.hw__projects-desc::-webkit-scrollbar-thumb {
  background-color: #888;
  /* Background colour of the slider */
  border-radius: 4px;
  /* Slider rounding */
}

/* Styles when the mouse hovers over the scrollbar */
.hw__projects-desc::-webkit-scrollbar-thumb:hover {
  background-color: var(--color-theme);
  /* Background colour on hover */
}

.hw__projects-link {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto auto 30px;
}

.hw__projects-link>a {
  height: 40px;
  width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background-color: var(--color-projects-button-bg);
  color: var(--color-projects-button-font);
  cursor: pointer;
}

.hw__projects-link>a:hover {
  background-color: var(--color-theme);
  color: var(--color-theme-font);
  scale: 1.1;
}

.hw__projects-link svg {
  font-size: 20px;
}

.hw__projects-link-demo+a {
  margin-left: 20px;
}

.hw__projects-item-tags {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 10px;
}

.hw__projects-item-tag {
  font-size: 0.875rem;
  padding: 4px 8px;
  background-color: var(--color-font);
  background-color: var(--color-font-reverse);
  border-radius: 16px;
}

@media screen and (max-width: 1000px) {
  .hw__projects-items {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media screen and (max-width: 700px) {
  .hw__projects-tabs {
    padding: 0 20px;
  }

  .hw__projects-item {
    width: 100%;
  }
}

@media screen and (max-width: 500px) {
  .hw__projects-tab-desc {
    margin: 10px auto 20px;
  }

  .hw__projects-items {
    grid-template-columns: repeat(1, 1fr);
  }
}