.scroll-container {
  position: relative;
  height: 54px;
  overflow: hidden;
}

.text-item {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 54px;
  font-size: 3rem;
  font-weight: bold;
  transform: translateY(100%);
}

.text-item:nth-child(1) {
  color: var(--color-theme);
}

.text-item:nth-child(2) {
  color: var(--color-theme2);
}

.text-item:nth-child(3) {
  color: rgb(218, 77, 26);
}

.text-item:nth-child(4) {
  color: #a22fd4;
}

.text-item.prev {
  transform: translateY(-100%);
  transition: .5s ease-in-out;
}

.text-item.show {
  transform: translateY(0);
  transition: .5s ease-in-out;
}

@media screen and (max-width: 600px) {
  .scroll-container {
    height: 44px;
  }

  .text-item {
    height: 44px;
    font-size: 2.5rem;
  }
}

@media screen and (max-width: 500px) {
  .scroll-container {
    height: 40px;
  }

  .text-item {
    height: 40px;
    font-size: 2rem;
  }
}