.hw__testimonials-item {
  width: calc((100% - 20px) / 2);
  padding: 20px;
  border: 1px solid var(--color-border);
  margin: 0 20px 20px 0;
  border-radius: 16px;
  transition: .3s;
  display: flex;
  flex-direction: column;
  gap: 20px;
  background: linear-gradient(to bottom, var(--color-border) 0, transparent 90px);
}

.hw__testimonials-item-img {
  width: 60px;
  height: 60px;
  border: 1px solid var(--color-border);
  border-radius: 50%;
  background-color: var(--color-border);
  overflow: hidden;
  flex-shrink: 0;
}

.hw__testimonials-item-img img {
  width: 100%;
  object-fit: cover;
}

.hw__testimonials-item-row {
  display: flex;
  flex-direction: row;
  gap: 16px;
}

.hw__testimonials-item-col {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 8px;
}

.hw__testimonials-item-name {
  font-size: 1.125rem;
  font-weight: bold;
}

.hw__testimonials-item-title {
  color: var(--color-grey);
}

.hw__testimonials-item p {
  font-size: 15px;
  white-space: break-spaces;
  text-align: justify;
}

.hw__testimonials-item p svg {
  font-size: 1.25rem;
  vertical-align: text-top;
  color: var(--color-grey);
}

.hw__testimonials-item p svg:first-child {
  margin-right: 8px;
}

.hw__testimonials-item p svg:last-child {
  margin-left: 8px;
}

@media screen and (max-width: 1040px) {
  #hw__testimonials {
    padding: 0 20px;
  }
}

@media screen and (max-width: 800px) {
  .hw__testimonials-item {
    width: 100%;
    margin: 0 0 16px 0;
  }
}