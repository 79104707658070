.hw__sidebar {
  display: none;
}

@media screen and (max-width: 1200px) {
  .hw__cross {
    height: 52px;
    width: 52px;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    inset: 0 20px auto auto;
    cursor: pointer;
  }

  .hw__cross svg {
    font-size: 16px;
    transition: 0.3s;
  }

  .hw__cross:hover svg {
    transform: scale(1.2);
    color: var(--color-theme);
  }

  .hw__sidebar {
    position: fixed;
    display: block;
    height: 100vh;
    width: 400px;
    background-color: var(--color-sidebar-bg);
    backdrop-filter: blur(10px);
    inset: 0 0 auto auto;
    z-index: 15;
    transform: translateX(100vw);
    transition: 0.5s;
  }

  .hw__sidebar ul {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    width: 100%;
  }

  .hw__sidebar ul li a {
    display: inline-block;
    padding: 20px 0;
    width: 100%;
    font-size: 20px;
    font-weight: bold;
    text-transform: capitalize;
    text-align: center;
    opacity: .6;
  }

  .hw__sidebar ul li a.current {
    color: var(--color-theme);
    opacity: 1;
  }

  .hw__sidebar ul li a:not(.current):hover {
    opacity: 1;
  }

  .hw__sidebar-show {
    transform: translateX(0);
  }
}

@media screen and (max-width: 500px) {
  .hw__sidebar {
    width: 100vw;
  }
}