#hw__timeline {
  display: flex;
  flex-direction: column;
}

.hw__timeline-item {
  display: flex;
  flex-direction: row;
  gap: 20px;
}

.hw__timeline-item-left {
  display: flex;
  flex-direction: column;
  width: max-content;
}

.hw__timeline-logo {
  width: 60px;
  aspect-ratio: 1;
  flex-shrink: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--color-logo-bg);
  border-radius: 50%;
  overflow: hidden;
  border: 2px solid var(--color-bg-grey);
}

.hw__timeline-logo img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.hw__timeline-line {
  background-color: var(--color-bg-grey);
  height: calc(100% - 50px);
  width: 2px;
  margin: auto;
}

.hw__timeline-item-right {
  padding: 20px;
  border-radius: 32px;
  background-color: var(--color-timeline-body-bg);
}

.hw__timeline-item .hw__timeline-item-right {
  max-width: 550px;
}

.hw__timeline-item:not(:last-child) .hw__timeline-item-right {
  margin-bottom: 40px;
}

.hw__timeline-tags {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
  margin-bottom: 10px;
}

.hw__timeline-tags span {
  padding: 4px 8px;
  background-color: var(--color-app-bg);
  font-size: 14px;
  color: var(--color-grey);
  width: max-content;
  border-radius: 20px;
  border: 1px solid var(--color-tag-border);
}

.hw__timeline-title {
  font-weight: bold;
  margin-bottom: 16px;
  font-size: 1.25rem;
}

.hw__timeline-title a {
  border-bottom: 1px solid;
}

.hw__timeline-subtitle {
  color: var(--color-desc);
}

ul.hw__timeline-desc {
  display: flex;
  flex-direction: column;
  gap: 8px;
  list-style-type: none;
  font-size: 15px;
}

ul.hw__timeline-desc li {
  padding-left: 1.5em;
  position: relative;
  word-wrap: break-word;
  text-align: justify;
  line-height: 1.3;
}

ul.hw__timeline-desc li::before {
  position: absolute;
  content: "●";
  left: 0;
  top: 0.15em;
  font-size: 0.8em;
}

@media screen and (max-width: 600px) {
  .hw__timeline-item {
    gap: 12px;
  }

  .hw__timeline-logo {
    width: 50px;
  }

  ul.hw__timeline-desc li {
    text-align: start;
  }
}