#hw__work {
  overflow: visible;
}

.hw__work {
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 0 20px;
  gap: 20px;
}

.hw__work-illustration img {
  height: 310px;
}

@media screen and (max-width: 900px) {
  .hw__work-illustration {
    display: none;
  }
}