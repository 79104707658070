.hw__backtotop {
  position: fixed;
  bottom: 50px;
  right: 50px;
  height: 50px;
  width: 50px;
  border-radius: 50%;
  background-color: var(--color-bg-grey);
  color: var(--color-font);
  border: none;
  outline: none;
  cursor: pointer;
  transition: 0.3s;
  box-shadow: var(--box-shadow-button);
  z-index: 10;
}

.hw__backtotop:hover {
  scale: 1.2;
  background-color: var(--color-theme);
  color: var(--color-theme-font);
}

@media screen and (max-width: 800px) {
  .hw__backtotop {
    display: none;
  }
}