#hw__footer {
  position: relative;
  display: inline-block;
  width: -webkit-fill-available;
  margin: 70px 30px 30px;
  background: var(--color-footer-bg);
  padding: 30px 30px 20px;
  border-radius: 32px;
  overflow: hidden;
}

.hw__footer-body {
  position: relative;
  display: flex;
  width: -webkit-fill-available;
  flex-direction: column;
  align-items: center;
  text-align: center;
  z-index: 1;
}

#hw__footer>svg {
  position: absolute;
  fill: var(--color-app-bg);
  opacity: .5;
}

#hw__footer>svg:nth-of-type(1) {
  inset: -40px auto auto -35px;
  height: 420px;
}

#hw__footer>svg:nth-of-type(2) {
  inset: -10px -70px auto auto;
  rotate: 10deg;
  height: 370px;
}

.hw__footer-subtitle {
  font-size: 1.125rem;
}

#hw__footer header {
  font-size: 3rem;
  font-weight: bold;
}

#hw__footer header:nth-of-type(2) {
  color: var(--color-theme);
  margin-bottom: 20px;
}

.hw__footer-subtitle {
  color: var(--color-desc);
}

.hw__footer {
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  gap: 20px;
  margin-top: 50px;
}

.hw__footer-left a {
  font-weight: bold;
}

.hw__footer-right {
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: 10px;
}

@media screen and (max-width: 700px) {
  #hw__footer {
    margin: 50px 0 0;
    border-radius: 0;
  }

  #hw__footer>svg {
    display: none;
  }

  .hw__footer-subtitle {
    font-size: 1rem;
  }

  #hw__footer header {
    font-size: 2rem;
  }
}