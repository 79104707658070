:root {
  --color-theme: #1fc7b6;
  --color-theme-trsp: #1fc7b64d;
  --color-theme-font: #ffffffe6;
  --color-theme-bright: #17d9c5;
  --color-theme-hover: #1a9b8e;
  --color-theme2: #2561da;
  --color-font: #000000e6;
  --color-font-reverse: #ffffffe6;
  --color-grey: rgba(0, 0, 0, 0.5);
  --color-black-trsp: #000000cc;
  --color-white-trsp: #ffffffcc;
  --color-black-trsp: rgba(0, 0, 0, 0.6);
  --color-bg-grey: var(--color-footer-bg);
  --color-app-bg: white;
  --color-navbar-bg: rgba(240, 240, 240, 0.65);
  --color-footer-bg: rgb(240, 240, 240);
  --color-input-bg: #fff;
  --color-sidebar-bg: var(--color-navbar-bg);
  --color-skills-icon-bg: #f0f0f0;
  --color-wave-bg: #e4f5f3;
  --color-logo-bg: #fff;
  --color-projects-card-bg: #ffffffb3;
  --color-projects-button-bg: var(--color-font);
  --color-projects-button-font: #fff;
  --color-certifications-list-bg: var(--color-footer-bg);
  --color-timeline-body-bg: var(--color-certifications-list-bg);
  --color-desc: #00000080;
  --color-border: #e0e0e0;
  --color-tag-border: #ccc;
  --color-button: var(--color-footer-bg);
  --box-shadow: 0 2px 16px -4px rgba(0, 0, 0, 0.15);
  --box-shadow-button: 0 1px 8px -2px rgba(0, 0, 0, 0.4);
  --box-shadow-bar: 2px 1px 8px -3px rgba(0, 0, 0, 0.3);
  --height-navbar: 50px;
  --animate-delay: 0.7s;
}

:root .dark {
  --color-theme: #1fb7a8;
  --color-theme-bright: var(--color-theme);
  --color-theme-hover: #8ee9e0;
  --color-font: #ffffffe6;
  --color-font-reverse: #000000e6;
  --color-grey: rgba(255, 255, 255, 0.5);
  --color-bg-grey: #3c404c;
  --color-app-bg: #16191e;
  --color-navbar-bg: var(--color-black-trsp);
  --color-footer-bg: var(--color-wave-bg);
  --color-input-bg: #000;
  --color-sidebar-bg: var(--color-navbar-bg);
  --color-skills-icon-bg: #000;
  --color-wave-bg: #242933;
  --color-logo-bg: #000;
  --color-projects-card-bg: #000000cc;
  --color-projects-button-bg: var(--color-font);
  --color-projects-button-font: #000;
  --color-certifications-list-bg: #000;
  --color-timeline-body-bg: var(--color-certifications-list-bg);
  --color-unselected-font: #ffffff4d;
  --color-desc: #ffffff80;
  --color-border: #32363a;
  --color-tag-border: #32363a;
  --color-button: var(--color-footer-bg);
  --box-shadow: 0 2px 16px -3px rgba(255, 255, 255, 0.15);
  --box-shadow-button: 0 1px 8px -2px rgba(255, 255, 255, 0.4);
  --box-shadow-bar: 2px 1px 8px -3px rgba(255, 255, 255, 0.3);
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  scroll-behavior: smooth;
  letter-spacing: 0.6px;
  list-style: none;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

*:focus {
  outline: none;
}

a {
  text-decoration: none;
  color: var(--color-font);
  transition: 0.3s;
  font-size: inherit;
}

a.hw__link-text:hover {
  color: var(--color-theme);
}

p {
  line-height: 1.6;
}

.hw__font-georgia {
  font-family: Georgia;
  letter-spacing: 0.3px;
}

.dark .hw__skills-icon,
.dark .hw__skills-icon-mini,
.dark .hw__about-img img,
.dark .hw__education-illustration img,
.dark .hw__work-illustration img,
.dark .hw__timeline-logo,
.dark .hw__projects-item-img img,
.dark .hw__certifications-content img,
.dark .hw__contact-illustration img,
.dark .hw__blogs-card {
  filter: brightness(0.9);
}

.lazyload-wrapper {
  height: 100%;
}

body {
  font-family: PingFang SC, -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@media screen and (max-width: 600px) {
  * {
    font-size: 14px;
  }
}

html.lenis,
html.lenis body {
  height: auto;
}

.lenis.lenis-smooth {
  scroll-behavior: auto !important;
}

.lenis.lenis-smooth [data-lenis-prevent] {
  overscroll-behavior: contain;
}

.lenis.lenis-stopped {
  overflow: hidden;
}

.lenis.lenis-smooth iframe {
  pointer-events: none;
}