.hw__contact {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.hw__contact-illustration img {
  width: 380px;
  margin: 0 0 0 20px;
}

.hw__contact-form {
  display: grid;
  width: 60%;
  gap: 20px;
  grid-template-columns: auto;
  grid-auto-rows: minmax(44px, auto);
  padding: 10px;
}

.hw__contact-info-wrap {
  display: flex;
  justify-content: space-around;
}

.hw__contact-info {
  display: flex;
  align-items: center;
  gap: 8px;
}

.hw__contact-form svg {
  font-size: 18px;
}

.hw__contact-form input,
.hw__contact-form textarea {
  background-color: var(--color-input-bg);
  border: 1px solid var(--color-border);
  border-radius: 22px;
  padding: 8px 14px;
  font-size: 1rem;
  color: var(--color-font);
  box-shadow: var(--box-shadow);
  font-family: PingFang SC;
  transition: 0.3s;
}

.hw__contact-form input:hover,
.hw__contact-form textarea:hover {
  border-color: var(--color-grey);
}

.hw__contact-form input:focus,
.hw__contact-form textarea:focus {
  border-color: var(--color-theme-bright);
}

.hw__contact-form textarea {
  resize: vertical;
}

#hw__contact {
  padding-bottom: 20px;
}

.hw__contact-send {
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media screen and (max-width: 1000px) {
  .hw__contact-form {
    width: 100%;
    padding: 0 20px;
  }

  .hw__contact-illustration {
    display: none;
  }
}

@media screen and (max-width: 600px) {
  .hw__contact-form {
    padding: 0 20px 10px;
  }
}

@media screen and (max-width: 500px) {
  .hw__contact-info-wrap {
    flex-direction: column;
    gap: 10px;
  }
}