.hw__button {
  width: fit-content;
  display: flex;
  align-items: center;
  gap: 12px;
  border-radius: 30px;
  font-weight: bold;
  box-shadow: var(--box-shadow-button);
  transition: 0.3s;
  cursor: pointer;
  color: var(--color-font);
}

.hw__button-contained {
  background-color: var(--color-button);
}

.hw__button-contained:hover {
  color: var(--color-theme-font);
  background-color: var(--color-theme);
}

.hw__button-outlined {
  border: 1px solid var(--color-font);
  background-color: transparent;
}

.hw__button-outlined:hover {
  border-color: var(--color-theme-hover);
  color: var(--color-theme-hover);
}

.hw__button-large {
  padding: 10px 18px;
  font-size: 1.125rem;
}

.hw__button-medium {
  padding: 8px 14px;
  font-size: 1rem;
}

.hw__button-large.hw__button-icon-only {
  padding: 12px;
}

.hw__button-medium.hw__button-icon-only {
  padding: 8px;
}

.hw__button-icon-only:hover {
  scale: 1.1;
}

.hw__button:focus {
  border: 1px solid var(--color-theme-bright);
}

.hw__button svg {
  font-size: 1.25rem;
}

@media screen and (max-width: 600px) {
  .hw__footer {
    flex-direction: column-reverse;
  }

  .hw__button-large {
    padding: 10px 18px;
  }

  .hw__button-medium {
    padding: 6px 12px;
  }
}