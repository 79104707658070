.hw__certifications {
  display: flex;
  gap: 20px;
  align-items: center;
  height: 490px;
  padding: 0 20px;
}

.hw__certifications-tabs {
  display: flex;
  justify-content: center;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 20px;
  margin: 0 auto 40px;
  width: fit-content;
}

.hw__certifications-tab-selected,
.hw__certifications-tab-selected:hover {
  color: var(--color-theme);
  border-color: var(--color-theme);
  cursor: default;
}

.hw__certifications-tab-sup {
  position: relative;
  top: -4px;
  margin-left: 4px;
  font-size: 0.875rem;
}

.hw__certifications-list {
  width: 460px;
  height: inherit;
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px;
  border-radius: 16px;
  background-color: var(--color-certifications-list-bg);
}

.hw__certifications-header {
  display: flex;
  gap: 12px;
  align-items: center;
}

.hw__certifications-header svg {
  font-size: 1.5rem;
}

.hw__certifications-header span {
  font-size: 1.25rem;
  font-weight: bold;
}

.hw__certifications-list-scroll {
  /* max-height: 370px; */
  overflow-y: auto;
  padding-right: 10px;
}

/* Change the background of the scrollbar track */
.hw__certifications-list-scroll::-webkit-scrollbar-track {
  background-color: var(--color-app-bg);
  border-radius: 4px;
}

/* Set the style of the scrollbar */
.hw__certifications-list-scroll::-webkit-scrollbar {
  width: 8px;
  /* Width of the scrollbar */
}

/* Set the style of the scrollbar slider */
.hw__certifications-list-scroll::-webkit-scrollbar-thumb {
  background-color: #888;
  /* Background colour of the slider */
  border-radius: 4px;
  /* Slider rounding */
}

/* Styles when the mouse hovers over the scrollbar */
.hw__certifications-list-scroll::-webkit-scrollbar-thumb:hover {
  background-color: var(--color-theme);
  /* Background colour on hover */
}

.hw__certifications-list ul {
  display: flex;
  flex-direction: column;
  overflow-y: auto;
}

.hw__certifications-list li {
  padding: 8px 10px;
  border-radius: 8px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  transition: .3s;
  cursor: pointer;
}

.hw__certifications-list li.current {
  color: var(--color-theme);
  font-weight: bold;
}

.hw__certifications-list li:nth-child(odd) {
  background-color: var(--color-app-bg);
}

.hw__certifications-list li:hover {
  color: var(--color-theme);
}

.hw__certifications-content {
  display: flex;
  flex-direction: column;
  gap: 20px;
  height: 100%;
  width: 100%;
  flex-grow: 1;
}

.hw__certifications-imgs {
  position: relative;
  flex-grow: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.hw__certifications-imgs img {
  position: absolute;
  max-width: 100%;
  max-height: 100%;
  border: 1px solid var(--color-border);
  border-radius: 16px;
  opacity: 0;
  transition: .3s;
}

.hw__certifications-imgs img.current {
  opacity: 1;
  z-index: 5;
}

.hw__certifications-imgs img.current:hover {
  transform: scale(1.03);
}

.hw__certifications-tags {
  display: flex;
  justify-content: center;
  gap: 10px;
  overflow-x: auto;
}

.hw__certifications-tags::-webkit-scrollbar {
  display: none;
}

.hw__certifications-tags span {
  font-size: 0.875rem;
  padding: 4px 8px;
  background-color: var(--color-font);
  background-color: var(--color-font-reverse);
  border: 1px solid var(--color-border);
  border-radius: 16px;
  flex-shrink: 0;
}

@media screen and (max-width: 1000px) {
  .hw__certifications {
    flex-direction: column;
    height: fit-content;
  }

  .hw__certifications-tabs {
    padding: 0 20px;
  }

  .hw__certifications-list {
    height: 460px;
  }

  .hw__certifications-imgs {
    width: 100%;
    height: 460px;
  }
}

@media screen and (max-width: 600px) {
  .hw__certifications-tabs {
    height: 165px;
    overflow: auto;
  }

  .hw__certifications-tabs::-webkit-scrollbar {
    display: none;
  }

  .hw__certifications-list {
    max-width: 100%;
    height: 360px;
  }

  .hw__certifications-imgs {
    height: 360px;
  }

  .hw__certifications-tags {
    justify-content: left;
  }
}