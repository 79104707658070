.hw__blogs {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
}

.hw__blogs-card {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;
  border-radius: 16px;
  overflow: hidden;
}

.hw__blogs-card img {
  width: 100%;
  height: 300px;
  object-fit: cover;
  transition: 1s ease-out;
}

.hw__blogs-card span {
  position: absolute;
  width: 100%;
  text-align: center;
  padding: 20px 20px 10px;
  bottom: 0;
  font-weight: bold;
}

.hw__blogs-card:hover img {
  scale: 1.1;
}

.hw__blogs-button {
  margin: 40px auto 0;
}

@media screen and (max-width: 1040px) {
  #hw__blogs {
    margin: 0 20px;
  }
}

@media screen and (max-width: 800px) {
  .hw__blogs {
    grid-template-columns: 1fr;
  }
}

@media screen and (max-width: 500px) {
  .hw__blogs-card img {
    height: 250px;
  }
}