.hw__navbar {
  position: fixed;
  z-index: 10;
  top: 0;
  left: 0;
  height: var(--height-navbar);
  width: 100%;
  background-color: var(--color-navbar-bg);
  backdrop-filter: blur(10px);
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 30px;
}

.hw__navbar>div {
  flex: 1;
}

.hw__navbar-logo {
  display: flex;
  align-items: center;
}

.hw__navbar-logo-img {
  width: 26px;
  height: 26px;
  margin-right: 16px;
}

.hw__navbar-logo p {
  font-size: 20px;
  font-weight: bold;
  width: max-content;
  cursor: pointer;
  position: relative;
  transition: .3s;
}

.hw__navbar-logo p::before {
  content: 'TOP';
  opacity: 0;
  scale: 0.8;
  border-radius: 12px;
  color: var(--color-theme-font);
  background: var(--color-theme);
  position: absolute;
  text-align: center;
  transition: .3s;
  height: 100%;
  width: 100%;
}

.hw__navbar-logo p:hover::before {
  opacity: 1;
  scale: 1;
}

.hw__navbar-logo p:hover {
  color: var(--color-navbar-bg);
}

.hw__navbar-menu ul {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 8px;
}

.hw__navbar-menu li a {
  font-weight: 500;
  font-size: 0.875rem;
  text-transform: capitalize;
  padding: 6px 8px;
  border-radius: 30px;
}

.hw__navbar-menu li a.current {
  background-color: var(--color-theme);
  color: var(--color-theme-font);
}

.hw__navbar-menu li a:not(.current):hover {
  color: var(--color-theme);
}

.hw__navbar-func {
  height: 100%;
  display: flex;
  justify-content: flex-end;
}

.hw__navbar-theme {
  display: flex;
  height: 100%;
  align-items: center;
}

.hw__navbar-func svg {
  font-size: 20px;
  transition: 0.3s;
  margin: 0 16px;
}

.hw__navbar-func>div {
  cursor: pointer;
}

.hw__navbar-func>div:hover svg {
  color: var(--color-theme);
  transform: scale(1.2);
}

.hw__navbar-menu-mb {
  display: none;
}

@media screen and (max-width: 1200px) {
  .hw__navbar {
    padding: 0 20px;
  }

  .hw__navbar-menu {
    display: none;
  }

  .hw__navbar-menu-mb {
    display: flex;
    height: 100%;
    align-items: center;
  }
}