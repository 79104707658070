#hw__about {
  margin: 0 auto;
  max-width: 1200px;
  padding: calc(var(--height-navbar) + 90px) 20px 0;
  display: flex;
  justify-content: space-around;
  align-items: center;
  gap: 20px;
  background-image: radial-gradient(circle at center var(--height-navbar), rgba(31, 199, 182, 0.4) 0%, rgba(31, 199, 182, 0.2) 25%, transparent 70%);
}

.hw__about-content {
  max-width: 700px;
  display: flex;
  flex-direction: column;
}

.hw__about-content p:nth-child(1) {
  font-size: 1.75rem;
}

.hw__about-name {
  font-size: 3rem;
  font-weight: bold;
}

.hw__about-desc {
  max-width: 570px;
  font-size: 1.125rem;
  color: var(--color-grey);
  margin-bottom: 26px;
  text-align: justify;
}

.hw__about-jobtitles {
  margin: 10px 0 16px;
}

.hw__about-links {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
}

.hw__about-img {
  height: 480px;
  aspect-ratio: auto;
  border-radius: 24px;
  transform: skewY(-6deg);
  animation: floating 4s ease-in-out infinite;
  transition: .3s;
}

.hw__about-img:hover {
  transform: skewY(0) scale(1.05);
}

@media screen and (max-width: 900px) {
  #hw__about {
    flex-direction: column-reverse;
    padding: calc(var(--height-navbar) + 60px) 20px 0;
  }

  .hw__about-content {
    margin-right: 0;
  }

  .hw__about-img {
    height: 400px;
    margin-bottom: 30px;
    animation: floating_mb 3s ease-in-out infinite;
  }

  .hw__about-links {
    justify-content: center;
  }
}

@media screen and (max-width: 600px) {
  .hw__about-content p:nth-child(1) {
    font-size: 1.375rem;
  }

  .hw__about-name {
    font-size: 2.5rem;
  }

  .hw__about-desc {
    font-size: 1rem;
  }
}

@media screen and (max-width: 500px) {
  .hw__about-name {
    font-size: 2rem;
  }
}

@keyframes floating {
  0% {
    translate: 0 0;
  }

  50% {
    translate: 0 16px;
  }

  100% {
    translate: 0 -0px;
  }
}

@keyframes floating_mb {
  0% {
    translate: 0 0px;
  }

  50% {
    translate: 0 10px;
  }

  100% {
    translate: 0 -0px;
  }
}