.hw__skills-boxes-group {
  position: relative;
  overflow: hidden;
}

.hw__skills-boxes {
  position: absolute;
  display: inline-flex;
  flex-wrap: nowrap;
  animation: slide-left 45s linear infinite;
  transition: 0.3s;
}

.hw__skills-boxes-group:hover .hw__skills-boxes {
  animation-play-state: paused;
  opacity: 0;
}

.hw__skills-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100px;
  height: 100px;
  border-radius: 20px;
  margin: 10px;
  box-shadow: var(--box-shadow);
}

.hw__skills-icon img {
  height: 70px;
  width: 70px;
  object-fit: contain;
}

.hw__skills-scroll-box {
  pointer-events: none;
}

.hw__skills-boxes-mini {
  top: 0;
  display: flex;
  flex-wrap: wrap;
  opacity: 0;
  transition: 0.3s;
  padding: 0 20px;
  gap: 10px;
}

.hw__skills-boxes-group:hover .hw__skills-boxes-mini {
  opacity: 1;
}

.hw__skills-icon-mini {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 20px;
  padding: 10px;
  box-shadow: var(--box-shadow);
}

.hw__skills-icon-mini img {
  width: 20px;
  height: 20px;
  object-fit: contain;
}

.hw__skills-icon-mini-name {
  font-weight: bold;
  padding-left: 10px;
}

.hw__skills-bars {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 0 20px;
}

.hw__skills-bar {
  width: calc((100% - 20px) / 2);
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 20px;
}

.hw__skills-bar:nth-child(odd) {
  margin-right: 20px;
}

.hw__skills-bar-text {
  width: 100px;
  text-align: center;
  margin-right: 20px;
}

.hw__skills-bar-text-name {
  font-weight: bold;
  font-size: 18px;
  margin-bottom: 6px;
}

.hw__skills-bar-text-percentage {
  color: var(--color-grey);
}

.hw__skills-bar-progress {
  width: calc(100% - 100px - 20px);
  height: 8px;
  border-radius: 5px;
  background-color: var(--color-input-bg);
  overflow: hidden;
  box-shadow: var(--box-shadow-bar);
}

.hw__skills-bar-progress-colored {
  height: 8px;
  border-radius: 5px;
  background-color: var(--color-theme);
}

@media screen and (max-width: 600px) {
  .hw__skills-scroll-box {
    overflow-x: hidden;
    overflow-y: auto;
    max-height: 150px;
    scrollbar-width: none;
    -ms-overflow-style: none;
    pointer-events: all;
  }

  .hw__skills-scroll-box::-webkit-scrollbar {
    display: none;
  }

  .hw__skills-boxes-mini {
    width: 100vw;
  }

  .hw__skills-icon-mini {
    padding: 8px;
  }

  .hw__skills-icon-mini img {
    width: 18px;
    height: 18px;
    object-fit: contain;
  }

  .hw__skills-bar {
    width: 100%;
    margin-right: 0 !important;
  }

  .hw__skills-icon {
    width: calc((100vw - 12px * 5) / 5);
    height: calc((100vw - 12px * 5) / 5);
    margin: 0 6px;
    border-radius: 12px;
  }

  .hw__skills-icon img {
    max-width: calc((100vw - 12px * 5) / 5 * 0.7);
    max-height: calc((100vw - 12px * 5) / 5 * 0.7);
  }
}

@keyframes slide-left {
  from {
    transform: translateX(0);
  }

  to {
    transform: translateX(calc((5 / 10) * -100%));
  }
}