.hw__section {
  margin: 90px auto 0;
}

.hw__section header {
  font-family: sans-serif;
  font-size: 6rem;
  font-weight: bold;
  text-transform: capitalize;
  margin-bottom: 30px;
  text-align: center;
  color: transparent;
  -webkit-text-stroke: 2px var(--color-grey);
}

.dark .hw__section header {
  text-shadow: 0 0 20px rgba(255, 255, 255, 0.3);
}

.hw__section-desc {
  color: var(--color-desc);
  margin-bottom: 40px;
  text-align: center;
  font-style: italic;
}

@media screen and (max-width: 1000px) {
  .hw__section-desc {
    padding: 0 20px;
  }

  .hw__section header {
    font-size: 5rem;
  }
}

@media screen and (max-width: 600px) {
  .hw__section {
    margin-top: 70px;
  }

  .hw__section header {
    font-size: 4rem;
    margin-bottom: 30px;
  }

  .hw__section-desc {
    text-align: justify;
  }
}