.hw__wave {
  margin-top: 50px;
}

.hw__wave .hw__section {
  margin: 0 auto;
}

.hw__wave+.hw__section {
  margin-top: 20px;
}

.hw__wave1,
.hw__wave2 {
  width: 100%;
  display: block;
  filter: brightness(1) !important;
}

.hw__wave-bg {
  background-color: var(--color-wave-bg);
}